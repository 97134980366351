/** AUTHOR: Tecnologie per Persone di Domenico Monaco - LICENSE: MIT - LINK: https://www.gdprcommons.it */

#GDPR_Commons>* {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: 'Trebuchet MS', sans-serif;
}

#GDPR_Commons #gdpr-commons-fixedicon .fixedicon {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    color: #fff;
    border-radius: 100%;
    box-shadow: 2px 2px 3px #333;
    width: 3rem;
    height: 3rem;
    aspect-ratio: 1;
    background-color: #fec330;
    padding: 0.8rem;
    text-align: center;
    cursor: pointer;
}

#GDPR_Commons #gdpr-commons-fixedicon .fixedicon:hover {
    fill: #fec330;
    background-color: #000;
}

#GDPR_Commons #gdpr-commons-modal {
    background: #ffffff;
    border-radius: 0.5em;
    left: 50%;

    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    max-width: 470px;
    text-align: left;
    padding: 15px;
    padding-bottom: 7px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
}

#GDPR_Commons #gdpr-commons-modal-overlay {
    background: #00000050;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
}

#GDPR_Commons #gdpr-commons-modal-overlay.fadeIN-display {
    -webkit-animation: animateIN 700ms;
    /* Chrome, Safari, Opera */
    animation: animateIN 700ms;
    visibility: visible;
    opacity: 1;
}

#GDPR_Commons #gdpr-commons-modal-overlay.fadeOUT-display {
    -webkit-animation: animateOUT 700ms;
    /* Chrome, Safari, Opera */
    animation: animateOUT 700ms;
    visibility: hidden;
    opacity: 0;
}

#GDPR_Commons #gdpr-commons-modal.fadeIN-display {
    -webkit-animation: animateIN 900ms;
    /* Chrome, Safari, Opera */
    animation: animateIN 900ms;
    visibility: visible;
    opacity: 1;
}

#GDPR_Commons #gdpr-commons-modal.fadeOUT-display {
    -webkit-animation: animateOUT 900ms;
    /* Chrome, Safari, Opera */
    animation: animateOUT 900ms;
    visibility: hidden;
    opacity: 0;
}

@keyframes animateOUT {
    0% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes animateIN {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

#GDPR_Commons #gdpr-commons-modal .gdpr-commons-modal-middle,
#GDPR_Commons #gdpr-commons-modal .gdpr-commons-modal-footer,
#GDPR_Commons #gdpr-commons-modal .gdpr-commons-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#GDPR_Commons #gdpr-commons-modal .gdpr-commons-modal-header .gdpr-commons-modal-title h3 {
    margin: 0px;
    margin-bottom: 5px;
}

#GDPR_Commons #gdpr-commons-modal .gdpr-commons-modal-middle {
    margin-bottom: 1rem;
}

#GDPR_Commons #gdpr-commons-modal .gdpr-commons-modal-content {
    font-size: 9px;
}

#GDPR_Commons #gdpr-commons-modal .gdpr-commons-modal-content p {
    text-align: justify;
    margin: 0px;
}

#GDPR_Commons button.buttons,
#GDPR_Commons a.buttons {
    border-radius: 0.3rem;
    border: none;
    cursor: pointer;
    padding: 0.6rem 15px;
    background-color: #fec330;
}

#GDPR_Commons button.buttons:hover,
#GDPR_Commons a.buttons:hover {
    background-color: #000;
    color: #fec330;
}

#GDPR_Commons #gdpr-commons-modal-credits {
    font-size: 0.8rem;
    margin: 15px 0px 0px 0px;
    color: #6e6a6a;
    width: 100%;
}

@media screen and (max-width: 420px) {

    #GDPR_Commons #gdpr-commons-modal .gdpr-commons-modal-middle,
    #GDPR_Commons #gdpr-commons-modal .gdpr-commons-modal-footer,
    #GDPR_Commons #gdpr-commons-modal .gdpr-commons-modal-header {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
    }

    #GDPR_Commons #gdpr-commons-modal .gdpr-commons-modal-middle > *,
    #GDPR_Commons #gdpr-commons-modal .gdpr-commons-modal-footer > *,
    #GDPR_Commons #gdpr-commons-modal .gdpr-commons-modal-header > *,
    #GDPR_Commons #gdpr-commons-modal-credits {
        display: block;
        width: 100%;
        text-align: center;
    }
}
